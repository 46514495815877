












































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import metaStore from "@/store/modules/meta";
import pagination from "@/components/UIComponents/Pagination.vue";
import { Stop } from "@/store/models/meta"

@Component({
  components: {
    pagination,
  },
})
export default class StopListView extends Vue {

  qUsers: Stop[] = [];

  async created() {
    await metaStore.loadStop();
  }

  get stopList(){
    return metaStore.stopList;
  }

  get AdminRouter() {
    return AdminRouter;
  }
}
